import React from 'react';
import {Button, Col, Row} from 'react-bootstrap';
import Card from '../../elements/ReactBootstrap/Card';
import Content from '../../partials/Content.jsx';
import {useLazyListVouchersForPaymentsQuery} from "../../../features/apiSlice.js";
import Alert from "../../elements/ReactBootstrap/Alert.jsx";
import ErrorWidget from "../../widgets/ErrorWidget.jsx";
import SmallSpinner from "../../elements/Spinner/SmallSpinner.jsx";
import WeekSelectionWidget from "../../widgets/Report/Selection/WeekSelectionWidget.jsx";
import DateRangeSelectionWidget from "../../widgets/Report/Selection/DateRangeSelectionWidget.jsx";
import {useNavigate, useParams} from "react-router-dom";
import MonthlyGridWidget from "../../widgets/Report/MonthlyGridWidget.jsx";

const MonthlyReports = () => {
    const {action} = useParams();
    const navigate = useNavigate();

    const [generate, {
        data: vouchersList,
        error,
        isUninitialized,
        isLoading,
        isFetching,
        isSuccess,
        isError,
    }] = useLazyListVouchersForPaymentsQuery();

    const vouchers = isSuccess ? vouchersList.filter(voucher =>
        !voucher.void
    ) : [];

    const gridRef = React.createRef();

    return (<Content title="Месечен отчет">
        <Row className="row-cols-1 gy-3 flex-grow-1 flex-column">
            <Col>
                <Card outline>
                    <Row className="row-cols-1 row-cols-sm-2 gy-3">
                        <Col>
                            <WeekSelectionWidget onSubmit={values => {
                                navigate('/reports/payments', {
                                    replace: true,
                                });

                                return generate(values);
                            }} autoSubmit={action === 'generate'} />
                        </Col>
                        <Col>
                            <DateRangeSelectionWidget onSubmit={values => generate(values)} />
                        </Col>
                    </Row>
                </Card>
                <Card>
                    <Row>
                    <Col sm={8}>
                        <Button variant="outline-secondary" className="ms-2" onClick={() => {
                            if (!gridRef.current) {
                                return;
                            }

                            const {columnApi} = gridRef.current;
                            columnApi.resetColumnState();
                        }}>Нулиране</Button>
                    </Col>
                    </Row>
                </Card>
            </Col>
            <Col className="flex-grow-1">
                {isUninitialized && <Alert variant="secondary" heading="Моля, изберете седмица или период." />}
                {isError && <ErrorWidget error={error} />}
                {(isLoading || isFetching) && <SmallSpinner />}
                {isSuccess && <MonthlyGridWidget rowData={vouchers} ref={gridRef}/>}
            </Col>
        </Row>
    </Content>);
};

export default MonthlyReports;
