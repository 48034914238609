import {forwardRef, lazy, Suspense, useCallback, useMemo} from 'react';
import {agMultiFilter, agParseDate, agSort} from '../../../utils.js';
import PremiumAmount from '../../elements/Values/PremiumAmount.jsx';
import PropTypes from 'prop-types';
import PolicyInfoButton from '../NavigationButtons/PolicyInfoButton.jsx';
import StickerInfoButton from '../NavigationButtons/StickerInfoButton.jsx';
import GreenCardInfoButton from '../NavigationButtons/GreenCardInfoButton.jsx';
import useCurrentUserHasRole from '../../../hooks/useCurrentUserHasRole.js';
import DateShort from '../../elements/DateTime/DateShort.jsx';
import DetailsIcon from '../../elements/Icons/DetailsIcon.jsx';
import {useGetCurrentUserQuery} from '../../../features/apiSlice.js';
import Card from '../../elements/ReactBootstrap/Card.jsx';
import localeText from '../../elements/AgGrid/locale.bg';
import useBrokerVoucherCommission from '../../../hooks/useBrokerVoucherCommission.js';
import {defaultComparator} from '@ag-grid-community/core/dist/esm/es6/utils/generic';
import SmallSpinner from '../../elements/Spinner/SmallSpinner.jsx';
import useUsersVoucherCommission from "../../../hooks/useUsersVoucherCommission.js";

const AgGridEnterprise = lazy(() => import('../../elements/AgGrid/AgGridEnterprise.js'));
const AgGridReact = lazy(() => import('../../elements/AgGrid/AgGridReactWrapper'));

const MonthlyGridWidget = forwardRef(({
    rowData,
    actionButton,
    showExtraControls,
}, ref) => {
    const {
        data: user,
    } = useGetCurrentUserQuery(undefined, {
        skip: false,
    });

    const { getBrokerVoucherCommissionCoefficient, getBrokerVoucherCommissionAmount } = useBrokerVoucherCommission(user);
    const { getUserVoucherCommissionPercent, getUserVoucherCommissionAmount } = useUsersVoucherCommission();

    const getFirstInstallment = useCallback(voucher => (voucher?.installments || []).concat().shift(), []);
    const getPolicy = useCallback(voucher => getFirstInstallment(voucher)?.policy, [getFirstInstallment]);
    const getInstallmentNumbers = useCallback(
        voucher => (voucher?.installments || []).map(installment => installment.number).join(','),
        []
    );

    const animateRows = true;

    const onGridReady = useCallback(event => agSort(event, [{
        colId: 'issueDate',
        sort: 'asc',
    }]), []);

    const premiumColumnDefs = useMemo(() => ({
        aggFunc: 'sum',
        cellRenderer: params => <PremiumAmount amount={params.value || 0} />,
        filter: 'agNumberColumnFilter',
        type: 'rightAligned',
        width: 118,
    }), []);

    const premiumCurrencyColumnDefs = useMemo(() => ({
        aggFunc: 'sum',
        cellRenderer: params => <PremiumAmount amount={params.value || 0} currency='EUR'/>,
        filter: 'agNumberColumnFilter',
        type: 'rightAligned',
        width: 118,
    }), []);

    const printColumnDefs = useMemo(() => ({
        enableRowGroup: false,
        filter: false,
        resizable: false,
        sortable: false,
        width: 101,
    }), []);

    const excelStyles = useMemo(() => [{
        id: 'stringType',
        dataType: 'String',
    }, {
        id: 'dateTimeType',
        dataType: 'DateTime',
        numberFormat: { format: "yyyy-mm-dd hh:mm:ss" }
    }], []);

    const multiFilter = agMultiFilter;
    const parseDate = useCallback((date, resetTime) => agParseDate(date, resetTime), []);

    const columnDefs = useMemo(() => [...(actionButton ? [{
        cellRenderer: actionButton,
    }] : []),
        {
            colId: 'contactClient',
            headerName: 'Лице за контакт',
            valueGetter: params => params.data && getPolicy(params.data).contactClient?.name,
            ...multiFilter,
        },{
            headerName: 'Офис',
            field: 'issueOffice.name',
            ...multiFilter,
        },{
            colId: 'status',
            headerName: 'Статус',
            valueGetter: params => params.data ? (
                params.data.void ? 'Анулирана' : params.data.setOfForDamage ? 'Прихващане' : 'Платена'
            ) : null,
            width: 118,
            ...multiFilter,
        },{
            colId: 'policy.productName',
            headerName: 'Продукт',
            valueGetter: params => params.data ? getPolicy(params.data).productName : null,
            cellRenderer: params => <>
                {params.value}{' '}<DetailsIcon details={params.data?.details} />
            </>,
            ...multiFilter,
        },{
            colId: 'policy.insuranceCompany.shortName',
            headerName: 'Застрахователна компания',
            valueGetter: params => params.data ? getPolicy(params.data).insuranceCompany.shortName : null,
            width: 251,
            ...multiFilter,
        },{
            colId: 'policy.number',
            filter: 'agTextColumnFilter',
            headerName: 'Полица №',
            valueGetter: params => params.data ? getPolicy(params.data).number : null,
            cellRenderer: params => params.data ?
                <PolicyInfoButton policy={getPolicy(params.data)} small /> : params.value,
            width: 258,
            cellClass: 'stringType'
        },{
            colId: 'insuredClientNames',
            headerName: 'Име на застрах. лице',
            valueGetter: params => params.data && getPolicy(params.data).insuredClients?.[0]?.insuredClient?.name,
            ...multiFilter,
        },{
            colId: 'insuredClientPins',
            headerName: 'ЕГН/ЕИК на застрах. лице',
            valueGetter: params => params.data && getPolicy(params.data).insuredClients?.[0]?.insuredClient?.pin,
            cellClass: 'stringType',
            ...multiFilter,
        },{
            colId: 'voucherNumber',
            headerName: 'Сметка №',
            field: 'number',
            valueGetter: params => params.data?.number ? params.data.number : null,
            ...multiFilter,
        },{
            colId: 'issueDate',
            filter: 'agDateColumnFilter',
            headerName: 'Платено',
            valueGetter: params => params.data?.issueDate,
            cellRenderer: params => <DateShort date={parseDate(params.data?.issueDate)} html />,
            width: 129,
            cellClass: 'stringType'
        },{
            colId: 'installments',
            headerName: 'Вноска',
            valueGetter: params => params.data ? getInstallmentNumbers(params.data) + '/' +
                getPolicy(params.data).installmentsCount : null,
            width: 120,
        },{
            filter: 'agTextColumnFilter',
            headerName: 'Стикер №',
            field: 'sticker.number',
            cellRenderer: params => params.data && params.data.sticker ?
                <StickerInfoButton sticker={params.data.sticker} small /> : params.value,
            width: 180,
        },{
            colId: 'greenCard.number',
            filter: 'agTextColumnFilter',
            headerName: 'СЗК №',
            valueGetter: params => params.data?.greenCard ? params.data.greenCard.series + params.data.greenCard.number : null,
            cellRenderer: params => params.data && params.data.greenCard ?
                <GreenCardInfoButton greenCard={params.data.greenCard} small /> : params.value,
            width: 177,
        },{
            headerName: 'Премия',
            field: 'premiumAmount',
            ...premiumColumnDefs,
        },{
            headerName: 'ГФ',
            field: 'guaranteeFundAmount',
            ...premiumColumnDefs,
        },{
            headerName: 'Стикер',
            field: 'stickerAmount',
            ...premiumColumnDefs,
        },{
            headerName: 'Данък',
            field: 'taxAmount',
            ...premiumColumnDefs,
        },{
            colId: 'userCommissionPercent',
            headerName: 'Консултант. %',
            valueGetter: params => params.data ?
                (getUserVoucherCommissionPercent(params.data,
                    params.data?.commissionUser ? params.data.commissionUser
                        : params.data?.issueUser ? params.data.issueUser
                            : getPolicy(params.data).issueUser) * 100).toFixed(2) : null
                ,
            aggFunc: null,
            cellRenderer: params => <>{params.value}%</>
        },{
            colId: 'userCommissionAmount',
            headerName: 'Консултант комисион',
            valueGetter: params => params.data ?
                getUserVoucherCommissionAmount(params.data,
                params.data?.commissionUser ? params.data.commissionUser
                    : params.data?.issueUser ? params.data.issueUser
                    : getPolicy(params.data).issueUser) : null,
            ...premiumColumnDefs,
        },{
            headerName: 'Общо',
            field: 'totalAmount',
            ...premiumColumnDefs,
        },{
            colId: 'commissionPercent',
            headerName: 'Комис. %',
            valueGetter: params => params.data ?
                (getBrokerVoucherCommissionCoefficient(params.data, getPolicy(params.data)) * 100)
                : null,
            aggFunc: null,
            cellRenderer: params => <>{params.value}%</>,
        },{
            colId: 'commissionAmount',
            headerName: 'Комисион',
            valueGetter: params => params.data ? getBrokerVoucherCommissionAmount(params.data, getPolicy(params.data)) : null,
            ...premiumColumnDefs,
        },{
            colId: 'differenceBrokerAgentPercent',
            headerName: 'Разлика %',
            valueGetter: params => params.data ?
                ((getBrokerVoucherCommissionCoefficient(params.data, getPolicy(params.data)) -
                    getUserVoucherCommissionPercent(params.data,
                        params.data?.commissionUser ? params.data.commissionUser
                            : params.data?.issueUser ? params.data.issueUser
                                : getPolicy(params.data).issueUser))* 100).toFixed(2)
                : null,
            cellRenderer: params => <>{params.value}%</>,
        },{
            colId: 'differenceBrokerAgentAmount',
            headerName: 'Разлика',
            valueGetter: params => params.data ?
                (getBrokerVoucherCommissionAmount(params.data, getPolicy(params.data)) -
                    getUserVoucherCommissionAmount(params.data,
                        params.data?.commissionUser ? params.data.commissionUser
                            : params.data?.issueUser ? params.data.issueUser
                                : getPolicy(params.data).issueUser))
                : null,
            ...premiumColumnDefs,
        },{
            headerName: 'Корекция %',
            colId: 'correctionPercent',
            valueGetter: params => params.data ? (getPolicy(params.data).correctionCommissionCoefficient * 100) : 0.0,
            cellRenderer: params => <>{params.value}%</>,
        } ,{
            headerName: 'Корекция',
            field: 'correctionAmount',
            ...premiumColumnDefs,
        },{
            colId: 'correctionDescription',
            headerName: 'Партиден номер',
            valueGetter: params => params.data ? getPolicy(params.data).correctionDescription : null,
        }
        ], [
        actionButton,
        premiumColumnDefs,
        printColumnDefs,
        premiumCurrencyColumnDefs,
    ]);

    const defaultColDef = useMemo(() => ({
        comparator: (valueA, valueB) => defaultComparator(
            valueA?.toLowerCase ? valueA.toLowerCase() : valueA,
            valueB?.toLowerCase ? valueB.toLowerCase() : valueB,
        ),
        enableRowGroup: true,
        filter: 'agTextColumnFilter',
        filterParams: {
            buttons: ['reset'],
        },
        floatingFilter: showExtraControls,
        resizable: true,
        sortable: true,
    }), [showExtraControls]);

    const rowGroupPanelShow = showExtraControls ? 'always' : 'never';

    const rowClassRules = useMemo(() => ({
        'grid-row-danger': params => params.data && params.data.void,
        'progress-bar-striped': params => params.data && params.data.void,
    }), []);

    return (<Suspense fallback={<Card><SmallSpinner /></Card>}>
        <AgGridEnterprise>
            <AgGridReact ref={ref} className="ag-theme-alpine" {...{
                animateRows, columnDefs, defaultColDef, localeText, onGridReady, rowData, rowGroupPanelShow,
                rowClassRules, excelStyles,
            }} />
        </AgGridEnterprise>
    </Suspense>);
});

MonthlyGridWidget.defaultProps = {
    showExtraControls: false,
};

MonthlyGridWidget.propTypes = {
    rowData: PropTypes.arrayOf(PropTypes.object.isRequired),
    actionButton: PropTypes.func,
    showExtraControls: PropTypes.bool,
};

export default MonthlyGridWidget;
