import {getPolicyAdditionalUrl} from '../../../url';
import PrintButton from '../ReactBootstrap/PrintButton';
import React from 'react';
import { printPolicyAdditional} from '../../../print';
import PropTypes from 'prop-types';

const PolicyAdditionalPrintButton = ({
    policy,

    ...props
}) => {
    return (
        <PrintButton icon="file-alt" printAction={() => printPolicyAdditional(policy.id)}
            viewUrl={getPolicyAdditionalUrl(policy.id)}
            downloadUrl={getPolicyAdditionalUrl(policy.id, true)}
            objId={policy.id} additional={true} {...props} />
    );
};

PolicyAdditionalPrintButton.defaultProps = {
    label: 'Допълнителен документ',
    objType: 'policy',
};

PolicyAdditionalPrintButton.propTypes = {
    label: PropTypes.string,
    objType: PropTypes.string,
    policy: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
};

export default PolicyAdditionalPrintButton;
