import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Button, ButtonGroup, Dropdown, Modal} from 'react-bootstrap';
import {
    useUploadGreenCardFileMutation,
    useUploadPolicyFileMutation,
    useUploadPolicyAdditionalFileMutation,
    useUploadVoucherFileMutation,
    useUploadVehicleFileMutation
} from "../../../features/apiSlice.js";
import useCurrentUserHasRole from "../../../hooks/useCurrentUserHasRole.js";

const PrintButton = ({
    icon,
    label,
    objType,
    printing,
    disabled,
    setDisabled,
    printAction,
    viewUrl,
    downloadUrl,
    small,
    objId,
    additional,

    ...props
}) => {
    const [buttonPrinting, setButtonPrinting] = useState(false);
    const inProgress = printing || buttonPrinting;

    const hasRoleUploadFiles = useCurrentUserHasRole('ROLE_UPLOAD_FILES');

    const [show, setShow] = useState(false);
    const [file, setFile] = useState(null);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleFileChange = (e) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
        }
    };

    const [uploadPolicy]= useUploadPolicyFileMutation();
    const [uploadPolicyAdditional]= useUploadPolicyAdditionalFileMutation();
    const [uploadVoucher]= useUploadVoucherFileMutation();
    const [uploadGreenCard]= useUploadGreenCardFileMutation();
    const [uploadVehicle]= useUploadVehicleFileMutation();
    const handleUpload = async () => {
        if (file && objId) {
            try {
                switch (objType) {
                    case "policy":
                        if (additional) {
                            await uploadPolicyAdditional(([objId, file]));
                        }
                        else {
                            await uploadPolicy(([objId, file]));
                        }
                        break;
                    case "voucher":
                        await uploadVoucher(([objId, file]));
                        break;
                    case "greenCard" :
                        await uploadGreenCard(([objId, file]));
                        break;
                    case "vehicle" :
                        await uploadVehicle(([objId, file]));
                        break;
                    default:
                        break;
                }
            }
            catch (e) {
                alert(e.message);
            }
            setFile(null);
            setShow(false);
        }
    };

    return (
        <>
        <Dropdown as={ButtonGroup} className="w-100" {...props}>
            <Button variant="outline-success" disabled={disabled} onClick={async () => {
                setButtonPrinting(true);
                setDisabled(true);
                try {
                    await printAction();
                }
                catch (e) {
                    alert(e.message);
                }
                setButtonPrinting(false);
                setDisabled(false);
            }} className={'w-100' + (small ? ' p-1' : '')}>
                <i className={'fas fa-' + (inProgress ? 'sync-alt fa-spin' : icon)}/>{' '}{label}
            </Button>
            <Dropdown.Toggle variant="outline-success" split disabled={disabled} className={small ? ' p-1' : ''} />
            {(viewUrl || downloadUrl) && <Dropdown.Menu role="menu" align="end">
                {viewUrl && <Dropdown.Item href={viewUrl} download target="_blank" rel="noreferrer">
                    <i className="fas fa-file-pdf" /> Преглед на файла
                </Dropdown.Item>}
                {downloadUrl && <Dropdown.Item href={downloadUrl} download target="_blank" rel="noreferrer">
                    <i className="fas fa-file-download" /> Сваляне на файла
                </Dropdown.Item>}
                {(hasRoleUploadFiles || objType === 'vehicle') && <Dropdown.Item onClick={handleShow} rel="noreferrer">
                    <i className="fas fa-file-upload" /> Качване на файл
                </Dropdown.Item>}
            </Dropdown.Menu>}
        </Dropdown>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Моля, изберете PDF файл</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <input id="file" type="file" onChange={handleFileChange} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Затваряне
                    </Button>
                    {file && <Button variant="primary" onClick={handleUpload}>
                        Качване
                    </Button>}
                </Modal.Footer>
            </Modal>
        </>
    );
};

PrintButton.defaultProps = {
    small: false,
};

PrintButton.propTypes = {
    icon: PropTypes.string.isRequired,
    label: PropTypes.node.isRequired,
    printing: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    setDisabled: PropTypes.func.isRequired,
    printAction: PropTypes.func.isRequired,
    viewUrl: PropTypes.string,
    downloadUrl: PropTypes.string,
    small: PropTypes.bool,
    objId: PropTypes.string,
    additional: PropTypes.bool,
};

export default PrintButton;
