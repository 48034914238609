import {forwardRef, lazy, Suspense, useCallback, useMemo, useState} from 'react';
import {agMultiFilter, agParseDate, agSort, paymentMethods} from '../../../utils.js';
import PremiumAmount from '../../elements/Values/PremiumAmount.jsx';
import {format} from 'date-fns';
import PropTypes from 'prop-types';
import PolicyInfoButton from '../NavigationButtons/PolicyInfoButton.jsx';
import StickerInfoButton from '../NavigationButtons/StickerInfoButton.jsx';
import GreenCardInfoButton from '../NavigationButtons/GreenCardInfoButton.jsx';
import PolicyPrintButton from '../../elements/PrintButtons/PolicyPrintButton.jsx';
import VoucherPrintButton from '../../elements/PrintButtons/VoucherPrintButton.jsx';
import GreenCardPrintButton from '../../elements/PrintButtons/GreenCardPrintButton.jsx';
import useCurrentUserHasRole from '../../../hooks/useCurrentUserHasRole.js';
import DateShort from '../../elements/DateTime/DateShort.jsx';
import InfoIcon from '../../elements/Icons/InfoIcon.jsx';
import DetailsIcon from '../../elements/Icons/DetailsIcon.jsx';
import VoucherEmailButton from '../../elements/Buttons/VoucherEmailButton.jsx';
import {useGetCurrentUserQuery, useSendVoucherEmailMutation} from '../../../features/apiSlice.js';
import Card from '../../elements/ReactBootstrap/Card.jsx';
import localeText from '../../elements/AgGrid/locale.bg';
import useBrokerVoucherCommission from '../../../hooks/useBrokerVoucherCommission.js';
import {defaultComparator} from '@ag-grid-community/core/dist/esm/es6/utils/generic';
import SmallSpinner from '../../elements/Spinner/SmallSpinner.jsx';
import useUsersVoucherCommission from "../../../hooks/useUsersVoucherCommission.js";

const AgGridEnterprise = lazy(() => import('../../elements/AgGrid/AgGridEnterprise.js'));
const AgGridReact = lazy(() => import('../../elements/AgGrid/AgGridReactWrapper'));

const ReportGridWidget = forwardRef(({
    rowData,
    actionButton,
    showExtraControls,
}, ref) => {
    const [printing, setPrinting] = useState(false);
    const [sending, setSending] = useState(false);

    const [send] = useSendVoucherEmailMutation();

    const showCommission = useCurrentUserHasRole('ROLE_SHOW_COMMISSION');

    const {
        data: user,
    } = useGetCurrentUserQuery(undefined, {
        skip: !showCommission,
    });

    const { getBrokerVoucherCommissionCoefficient, getBrokerVoucherCommissionAmount } = useBrokerVoucherCommission(user);
    const { getUserVoucherCommissionPercent, getUserVoucherCommissionAmount } = useUsersVoucherCommission();

    const getFirstInstallment = useCallback(voucher => (voucher?.installments || []).concat().shift(), []);
    const getPolicy = useCallback(voucher => getFirstInstallment(voucher)?.policy, [getFirstInstallment]);
    const getInstallmentNumbers = useCallback(
        voucher => (voucher?.installments || []).map(installment => installment.number).join(','),
        []
    );

    const animateRows = true;

    const onGridReady = useCallback(event => agSort(event, [{
        colId: 'issueDate',
        sort: 'asc',
    }]), []);

    const premiumColumnDefs = useMemo(() => ({
        aggFunc: 'sum',
        cellRenderer: params => <PremiumAmount amount={params.value || 0} />,
        filter: 'agNumberColumnFilter',
        type: 'rightAligned',
        width: 118,
    }), []);

    const premiumCurrencyColumnDefs = useMemo(() => ({
        aggFunc: 'sum',
        cellRenderer: params => <PremiumAmount amount={params.value || 0} currency='EUR'/>,
        filter: 'agNumberColumnFilter',
        type: 'rightAligned',
        width: 118,
    }), []);

    const printColumnDefs = useMemo(() => ({
        enableRowGroup: false,
        filter: false,
        resizable: false,
        sortable: false,
        width: 101,
    }), []);

    const excelStyles = useMemo(() => [{
        id: 'stringType',
        dataType: 'String',
    }, {
        id: 'dateTimeType',
        dataType: 'DateTime',
        numberFormat: { format: "yyyy-mm-dd hh:mm:ss" }
    }], []);

    const multiFilter = agMultiFilter;
    const parseDate = useCallback((date, resetTime) => agParseDate(date, resetTime), []);

    const columnDefs = useMemo(() => [...(actionButton ? [{
        cellRenderer: actionButton,
    }] : []), {
        colId: 'issueDate.week',
        filter: 'agNumberColumnFilter',
        headerName: 'Седмица',
        valueGetter: params => params.data ? parseInt(format(new Date(params.data.issueDate), 'I')) : null,
        width: 133,
    },{
        colId: 'policyIssueUser',
        headerName: 'Издал полица',
        valueGetter: params => params.data ? getPolicy(params.data).issueUser?.shortName: null,
        ...multiFilter,
    },{
        headerName: 'Издал квитанция',
        field: 'issueUser.shortName',
        ...multiFilter,
    },{
        headerName: 'Комисион за',
        field: 'commissionUser.shortName',
        cellRenderer: params => <>
            {params.data?.commissionUser ?
                params.data.commissionUser.shortName : params.data?.issueUser ? params.data?.issueUser.shortName : null
            }
        </>,
        ...multiFilter,
    },{
        headerName: 'Офис',
        field: 'issueOffice.name',
        ...multiFilter,
    },{
        colId: 'policy.insuranceCompany.shortName',
        headerName: 'Застрахователна компания',
        valueGetter: params => params.data ? getPolicy(params.data).insuranceCompany.shortName : null,
        width: 251,
        ...multiFilter,
    },{
        colId: 'policy.productName',
        headerName: 'Продукт',
        valueGetter: params => params.data ? getPolicy(params.data).productName : null,
        cellRenderer: params => <>
            {params.value}{' '}<DetailsIcon details={params.data?.details} />
        </>,
        ...multiFilter,
    },{
        colId: 'installments',
        headerName: 'Вноска',
        valueGetter: params => params.data ? getInstallmentNumbers(params.data) + '/' +
            getPolicy(params.data).installmentsCount : null,
        width: 120,
    },{
        colId: 'policy.number',
        filter: 'agTextColumnFilter',
        headerName: 'Полица №',
        valueGetter: params => params.data ? getPolicy(params.data).number : null,
        cellRenderer: params => params.data ?
            <PolicyInfoButton policy={getPolicy(params.data)} small /> : params.value,
        width: 258,
        cellClass: 'stringType'
    },{
        filter: 'agTextColumnFilter',
        headerName: 'Стикер №',
        field: 'sticker.number',
        cellRenderer: params => params.data && params.data.sticker ?
            <StickerInfoButton sticker={params.data.sticker} small /> : params.value,
        width: 180,
    },{
        colId: 'greenCard.number',
        filter: 'agTextColumnFilter',
        headerName: 'СЗК №',
        valueGetter: params => params.data?.greenCard ? params.data.greenCard.series + params.data.greenCard.number : null,
        cellRenderer: params => params.data && params.data.greenCard ?
            <GreenCardInfoButton greenCard={params.data.greenCard} small /> : params.value,
        width: 177,
    }, {
        headerName: 'Премия',
        field: 'premiumAmount',
        ...premiumColumnDefs,
    },{
        headerName: 'Премия €',
        field: 'premiumAmountCurrency',
        ...premiumCurrencyColumnDefs,
    },{
        headerName: 'Данък',
        field: 'taxAmount',
        ...premiumColumnDefs,
    },{
        headerName: 'Данък €',
        field: 'taxAmountCurrency',
        ...premiumCurrencyColumnDefs,
    },{
        headerName: 'ГФ',
        field: 'guaranteeFundAmount',
        ...premiumColumnDefs,
    },{
        headerName: 'ГФ €',
        field: 'guaranteeFundAmountCurrency',
        ...premiumCurrencyColumnDefs,
    },{
        headerName: 'Стикер',
        field: 'stickerAmount',
        ...premiumColumnDefs,
    },{
        headerName: 'Стикер €',
        field: 'stickerAmountCurrency',
        ...premiumCurrencyColumnDefs,
    },{
        headerName: 'Общо',
        field: 'totalAmount',
        ...premiumColumnDefs,
    },{
        headerName: 'Общо €',
        field: 'totalAmountCurrency',
        ...premiumCurrencyColumnDefs,
    },{
        headerName: 'Корекция %',
        colId: 'correctionPercent',
        valueGetter: params => params.data ? (getPolicy(params.data).correctionCommissionCoefficient * 100) : 0.0,
        cellRenderer: params => <>{params.value}%</>,
    } ,{
        headerName: 'Корекция',
        field: 'correctionAmount',
        ...premiumColumnDefs,
    }, ...(showCommission ? [{
        colId: 'commissionPercent',
        headerName: 'Комис. %',
        valueGetter: params => params.data ?
            (getBrokerVoucherCommissionCoefficient(params.data, getPolicy(params.data)) * 100)
            : null,
        aggFunc: null,
        cellRenderer: params => <>{params.value}%</>,
    },{
        colId: 'commissionAmount',
        headerName: 'Комисион',
        valueGetter: params => params.data ? getBrokerVoucherCommissionAmount(params.data, getPolicy(params.data)) : null,
        ...premiumColumnDefs,
    }] : []),{
        colId: 'userCommissionPercent',
        headerName: 'Консултант. %',
        valueGetter: params => params.data && params.data?.issueUser ?
            (getUserVoucherCommissionPercent(params.data,
                params.data?.commissionUser ? params.data.commissionUser : params.data.issueUser) * 100).toFixed(2)
            : null,
        aggFunc: null,
        cellRenderer: params => <>{params.value}%</>
    },{
        colId: 'userCommissionAmount',
        headerName: 'Консултант комисион',
        valueGetter: params => params.data && params.data?.issueUser ? getUserVoucherCommissionAmount(params.data,
            params.data?.commissionUser ? params.data.commissionUser : params.data.issueUser): null,
        ...premiumColumnDefs,
    },{
        colId: 'installment.dueDate',
        filter: 'agDateColumnFilter',
        headerName: 'Падеж',
        valueGetter: params => parseDate(getFirstInstallment(params.data)?.dueDate),
        cellRenderer: params => <DateShort date={params.value} />,
        width: 129,
    },{
        colId: 'issueDate',
        filter: 'agDateColumnFilter',
        headerName: 'Платено',
        valueGetter: params => params.data?.issueDate,
        cellRenderer: params => <DateShort date={parseDate(params.data?.issueDate)} html />,
        width: 129,
        cellClass: 'stringType'
    },{
        colId: 'paymentMethod',
        headerName: 'Начин на плащане',
        valueGetter: params => params.data ? paymentMethods[params.data.paymentMethod] : null,
        ...multiFilter,
    },{
        colId: 'paymentDocumentType',
        headerName: 'Платежен документ',
        valueGetter: params => params.data ? (
            params.data.paymentMethod === 'BANK_ACCOUNT_INSURANCE_COMPANY' ?
                'Дебит нота' : 'Квитанция'
        ) : null,
        width: 207,
        ...multiFilter,
    },{
        colId: 'policy.issueInsuranceBroker.id',
        headerName: 'Собствена полица',
        valueGetter: params => params.data && getPolicy(params.data).issueInsuranceBroker ? ' Да' : 'Не',
        width: 207,
        ...multiFilter,
    },{
        colId: 'stored',
        filter: 'agDateColumnFilter',
        headerName: 'Отчетено',
        valueGetter: params => parseDate(params.data?.storeDate),
        cellRenderer: params => params.data?.storeDate ? <>
            <DateShort date={params.data.storeDate} html />{' '}
            {(params.data.storageUser || params.data.storageOffice) &&
                <InfoIcon onClick={() =>
                    alert([params.data.storageUser?.shortName, params.data.storageOffice?.name].join(' - '))
                } />}
        </> : 'Не',
        width: 137,
    },{
        colId: 'voucherNumber',
        headerName: 'Сметка №',
        field: 'number',
        valueGetter: params => params.data?.number ? params.data.number : null,
        ...multiFilter,
    },{
        colId: 'insuredClientNames',
        headerName: 'Име на застрах. лице',
        valueGetter: params => params.data && getPolicy(params.data).insuredClients?.[0]?.insuredClient?.name,
        ...multiFilter,
    },{
        colId: 'insuredClientPins',
        headerName: 'ЕГН/ЕИК на застрах. лице',
        valueGetter: params => params.data && getPolicy(params.data).insuredClients?.[0]?.insuredClient?.pin,
        cellClass: 'stringType',
        ...multiFilter,
    },{
        colId: 'contactClient',
        headerName: 'Лице за контакт',
        valueGetter: params => params.data && getPolicy(params.data).contactClient?.name,
        ...multiFilter,
    },{
        colId: 'status',
        headerName: 'Статус',
        valueGetter: params => params.data ? (
            params.data.void ? 'Анулирана' : params.data.setOfForDamage ? 'Прихващане' : 'Платена'
        ) : null,
        width: 118,
        ...multiFilter,
    },{
        colId: 'note',
        headerName: 'Бележка',
        valueGetter: params => params.data ? getPolicy(params.data).note : null,
        width: 75,
    },{
        colId: 'actions.print.policy',
        headerName: 'Полица',
        cellRenderer: params => params.data && getFirstInstallment(params.data).number === 1 &&
            <PolicyPrintButton small policy={getPolicy(params.data)} label="" printing={false} disabled={printing} setDisabled={setPrinting} />,
        ...printColumnDefs,
    },{
        colId: 'actions.print.voucher',
        headerName: 'Сметка',
        cellRenderer: params => params.data &&
            <VoucherPrintButton small voucher={params.data} label="" printing={false} disabled={printing}
                                setDisabled={setPrinting} />,
        ...printColumnDefs,
    },{
        colId: 'actions.print.greenCard',
        headerName: 'СЗК',
        cellRenderer: params => params.data && params.data.greenCard &&
            <GreenCardPrintButton small greenCard={params.data.greenCard} label="" printing={false} disabled={printing} setDisabled={setPrinting} />,
        ...printColumnDefs,
    },{
        colId: 'actions.sendEmail',
        headerName: 'Мейл',
        cellRenderer: params => params.data &&
            <VoucherEmailButton onClick={() => send(params.data.id)} disabled={sending}
                                setDisabled={setSending} label="" small />,
        ...printColumnDefs,
    },{
        colId: 'correctionDescription',
        headerName: 'Партиден номер',
        valueGetter: params => params.data ? getPolicy(params.data).correctionDescription : null,
    }], [
        actionButton,
        showCommission,
        premiumColumnDefs,
        printColumnDefs,
        premiumCurrencyColumnDefs,
    ]);

    const defaultColDef = useMemo(() => ({
        comparator: (valueA, valueB) => defaultComparator(
            valueA?.toLowerCase ? valueA.toLowerCase() : valueA,
            valueB?.toLowerCase ? valueB.toLowerCase() : valueB,
        ),
        enableRowGroup: true,
        filter: 'agTextColumnFilter',
        filterParams: {
            buttons: ['reset'],
        },
        floatingFilter: showExtraControls,
        resizable: true,
        sortable: true,
    }), [showExtraControls]);

    const rowGroupPanelShow = showExtraControls ? 'always' : 'never';

    const rowClassRules = useMemo(() => ({
        'grid-row-danger': params => params.data && params.data.void,
        'progress-bar-striped': params => params.data && params.data.void,
    }), []);

    return (<Suspense fallback={<Card><SmallSpinner /></Card>}>
        <AgGridEnterprise>
            <AgGridReact ref={ref} className="ag-theme-alpine" {...{
                animateRows, columnDefs, defaultColDef, localeText, onGridReady, rowData, rowGroupPanelShow,
                rowClassRules, excelStyles,
            }} />
        </AgGridEnterprise>
    </Suspense>);
});

ReportGridWidget.defaultProps = {
    showExtraControls: false,
};

ReportGridWidget.propTypes = {
    rowData: PropTypes.arrayOf(PropTypes.object.isRequired),
    actionButton: PropTypes.func,
    showExtraControls: PropTypes.bool,
};

export default ReportGridWidget;
