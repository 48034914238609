import {Table} from 'react-bootstrap';
import DateShort from '../../elements/DateTime/DateShort';
import PolicyInfoButton from '../NavigationButtons/PolicyInfoButton';
import ClientInfoButton from '../NavigationButtons/ClientInfoButton';
import VehicleInfoButton from '../NavigationButtons/VehicleInfoButton';
import PremiumAmount from '../../elements/Values/PremiumAmount';
import {Link} from 'react-router-dom';
import React, {useCallback, useMemo} from 'react';
import {useListPendingInstallmentsQuery, useListPendingPoliciesQuery} from '../../../features/apiSlice';
import ErrorWidget from '../ErrorWidget';
import PropTypes from 'prop-types';
import {formatDayDiff} from '../../../utils.js';
import MtplOfferButton from '../OfferButtons/MtplOfferButton.jsx';
import {compareAsc} from 'date-fns';
import SmallSpinner from '../../elements/Spinner/SmallSpinner.jsx';

const PendingPaymentsTable = ({
    office,
    pollingInterval,
}) => {
    const {
        data: installments,
        error: installmentsError,
        isSuccess: installmentsSuccess,
        isLoading: installmentsLoading,
        isFetching: installmentsFetching,
        isError: installmentsIsError,
    } = useListPendingInstallmentsQuery({
        office,
    }, {
        pollingInterval,
    });

    const {
        data: policies,
        error: policiesError,
        isSuccess: policiesSuccess,
        isLoading: policiesLoading,
        isFetching: policiesFetching,
        isError: policiesIsError,
    } = useListPendingPoliciesQuery({
        office,
    }, {
        pollingInterval,
    });

    const isSuccess = installmentsSuccess && policiesSuccess;
    const isLoading = installmentsLoading || policiesLoading;
    const isFetching = installmentsFetching || policiesFetching;

    const isPolicy = useCallback(entity => entity.hasOwnProperty('installments'), []);
    const isInstallment = useCallback(entity => entity.hasOwnProperty('policy'), []);
    const getDateColumn = useCallback(entity =>
        isPolicy(entity) ? 'endDate' : (isInstallment(entity) ? 'dueDate' : null),
    []);
    const getDate = useCallback(entity => {
        const dateColumn = getDateColumn(entity);
        return dateColumn ? entity[dateColumn] : null;
    }, []);

    const data = useMemo(() => [
        ...(installmentsSuccess ? installments : []),
        ...(policiesSuccess ? policies.filter(policy => policy.product.insuranceType !== 'travel-assistance') : []),
    ].sort((a, b) =>
        compareAsc(new Date(getDate(a)), new Date(getDate(b))),
    ), [installmentsSuccess, installments, policiesSuccess, policies]);

    const hasMtplCover = useCallback(policy => policy.product.hasMtplCover, []);

    return (<>
        {isLoading && <div className="m-3">
            <SmallSpinner />
        </div>}
        {isSuccess && <div className={'table-responsive' + (isFetching ? ' opacity-50 pe-none user-select-none' : '')}>
            <Table striped className="mb-0">
                <thead>
                <tr>
                    <th>Дата</th>
                    <th>Клиент</th>
                    <th>МПС/Бележка</th>
                    <th>ЗК</th>
                    <th />
                    <th>Сума</th>
                    <th>Вноска</th>
                    <th>Полица</th>
                </tr>
                </thead>
                <tbody>
                {data.map(entity => {
                    const entityIsPolicy = isPolicy(entity);
                    const entityIsInstallment = isInstallment(entity);
                    const date = getDate(entity);

                    if (!date) {
                        return null;
                    }

                    const policy = entityIsPolicy ? entity : (entityIsInstallment ? entity.policy : null);

                    if (!policy) {
                        return null;
                    }

                    return (<tr key={entity.id}>
                        <td>
                            <DateShort date={date} />
                            <br/>
                            <small className="text-nowrap">{formatDayDiff(date)}</small>
                        </td>
                        <td>
                            <ClientInfoButton client={policy.policyHolder || policy.insuredClients?.[0]?.insuredClient} textField="name" />
                        </td>
                        <td>
                            {policy.insuredVehicle ?
                                <VehicleInfoButton vehicle={policy.insuredVehicle} textField="plateNumber" />
                            : policy.note}

                        </td>
                        <td>
                            {policy.insuranceCompany.shortName}
                        </td>
                        {entityIsInstallment && <>
                            <td>
                                <Link to={'/installment-payment/policy/' + policy.id}
                                      className="btn btn-outline-success">Плати</Link>
                            </td>
                            <td>
                                <PremiumAmount amount={entity.dueAmount} />
                                (<PremiumAmount amount={entity.dueAmountCurrency} currency='EUR'/>)
                            </td>
                            <td>
                                {entity.number} / {policy.installmentsCount}
                            </td>
                        </>}
                        {entityIsPolicy && <td colSpan={3}>
                            {hasMtplCover(policy) && <MtplOfferButton client={policy.policyHolder}
                                vehicle={policy.insuredVehicle} />}
                        </td>}
                        <td>
                            <PolicyInfoButton policy={policy} />
                        </td>
                    </tr>);
                })}
                </tbody>
            </Table>
        </div>}
        {installmentsIsError && <div className="m-3">
            <ErrorWidget error={installmentsError} />
        </div>}
        {policiesIsError && <div className="m-3">
            <ErrorWidget error={policiesError} />
        </div>}
    </>);
};

PendingPaymentsTable.defaultProps = {
    pollingInterval: 0,
};

PendingPaymentsTable.propTypes = {
    pollingInterval: PropTypes.number,
    office: PropTypes.string,
};

export default PendingPaymentsTable;
