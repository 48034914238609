import {getGreenCardUrl, getPolicyUrl, getVoucherUrl, getVehicleUrl, getPolicyAdditionalUrl} from './url';

const printDoc = async url => new Promise((resolve, reject) => {
    const frame = document.createElement('iframe');

    let captureBlur = false;
    let captureFocus = false;

    frame.hidden = true;

    const msgHandler = e => {
        if (!e.data || !e.data.status) {
            return;
        }

        console.log('frontend: receive', e.data);

        const postMsg = msg => {
            console.log('frontend: send', msg);
            e.source.postMessage(msg, '*');
        };

        switch (e.data.status) {
            case 'init':
                break;
            case 'noUrl':
                cleanup();
                reject(new Error('Frame did not receive URL'));
                break;
            case 'loadedSuccess':
                postMsg({
                    cmd: 'print',
                });
                break;
            case 'loadedError':
                cleanup();
                reject(new Error('Грешка при изтегляне на файла за печат\n' + (e.data?.error?.error?.message || '')));
                break;
            case 'printing':
                captureBlur = true;
                break;
            default:
                break;
        }
    };

    const blurHandler = () => {
        if (!captureBlur) {
            return;
        }

        console.log('frontend: blur');

        captureBlur = false;
        captureFocus = true;
    }

    const focusHandler = () => {
        if (!captureFocus) {
            return;
        }

        console.log('frontend: focus');

        captureFocus = false;
        cleanup();
        resolve();
    };

    const cleanup = () => {
        console.log('frontend: cleanup');

        window.removeEventListener('message', msgHandler);
        window.removeEventListener('focus', focusHandler);
        window.removeEventListener('blue', blurHandler);
        document.body.removeChild(frame);
        captureBlur = false;
        captureFocus = false;
    };

    window.addEventListener('message', msgHandler);
    window.addEventListener('focus', focusHandler);
    window.addEventListener('blur', blurHandler);
    document.body.appendChild(frame);

    console.log('frontend: load src');
    frame.src = url;
});

const getPrintUrl = url => import.meta.env.VITE_API_BASE_URL + '../print/lite.html?url=' + encodeURIComponent(url);

export const printPolicy = id => printDoc(getPrintUrl(getPolicyUrl(id)));
export const printPolicyAdditional = id => printDoc(getPrintUrl(getPolicyAdditionalUrl(id)));
export const printVehicle = id => printDoc(getPrintUrl(getVehicleUrl(id)));
export const printVoucher = id => printDoc(getPrintUrl(getVoucherUrl(id)));
export const printGreenCard = id =>printDoc(getPrintUrl(getGreenCardUrl(id)));
